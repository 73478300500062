/* CUSTOM STYLES GO HERE */

.blog-swiper-dt .swiper {
  width: 100%;
  margin-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 70px;
}

/* to change button image, use this: https://www.timo-ernst.net/blog/2020/09/12/arrows-in-react-swiper-js-how-to-customize-prev-next-button/ */
.blog-swiper-dt .swiper-button-next {
  color: black;
  margin-right: 0.75%;
  top: calc(50% - 35px);
}

.blog-swiper-dt .swiper-button-prev {
  color: black;
  margin-left: 0.75%;
  top: calc(50% - 35px);
}

.blog-swiper-dt .swiper-pagination-fraction {
  font-size: 2rem;
}

.blog-swiper-dt .swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.blog-swiper-dt .swiper-slide img {
  display: block;
  /* min-width: 75% !important;
  max-width: 75% !important; */
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.blog-swiper-dt .swiper {
  margin-left: auto;
  margin-right: auto;
}

.blog-swiper-dt .swiper-wrapper {
  align-items: center;
}
