/* CUSTOM STYLES GO HERE */

:root {
  --swiper-navigation-size: 33px;
}

.home-swiper .swiper {
  width: 100%;
  /* height: 39.0625%; */
}

/* to change button image, use this: https://www.timo-ernst.net/blog/2020/09/12/arrows-in-react-swiper-js-how-to-customize-prev-next-button/ */
.home-swiper .swiper-button-next {
  color: #d4d4d491;
  margin-right: 0.75%;
}
.swiper-button-prev {
  color: #d4d4d491;
  margin-left: 0.75%;
}

.home-swiper .swiper-pagination-bullet {
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.4);
}
.home-swiper .swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}

.home-swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.home-swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-swiper .swiper {
  margin-left: auto;
  margin-right: auto;
}
