.copy-component {
  bottom: 0.5rem;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.copy-component.copied {
  z-index: 999;
}

.copy-component .confirm-text {
  align-self: center;

  background-color: #5f5f5f;
  color: white;
  border-radius: 0.25rem;
  margin-right: 1.5rem;
  margin-top: 1.25rem;

  padding: 0.25rem 0.5rem;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
