  button{
    font-family:'Soehne-Kraftig';
  }

  .modal-open {
    overflow: hidden;
  }
  .modal {
    position: fixed;
    top: 20%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }
  .modal-content {
    position: relative;
    background-color:#364333;
    color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    outline: 0;
    text-align: center;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
  }
  .modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  .modal-header {
    padding: 5px;
    font-family:'Soehne-Kraftig';
  }
  .modal-header .close {
    margin-top: -2px;
  }
  .modal-title {
    margin: 0;
    line-height: 1.42857143;
    font-size: 1.4em;
  }
  .modal-body {
    font-family:'Soehne-Leicht';
    position: relative;
    padding: 12px 15px;
  }
  .modal-footer {
    padding: 15px;
    text-align: right;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
    }
    .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      color:#fff;
      text-align: center;
    }
    .modal-sm {
      width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg {
      width: 900px;
    }
  }
  .clearfix:before,
  .clearfix:after,
  .modal-header:before,
  .modal-header:after,
  .modal-footer:before,
  .modal-footer:after {
    display: table;
    content: " ";
  }
  .clearfix:after,
  .modal-header:after,
  .modal-footer:after {
    clear: both;
  }
  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .invisible {
    visibility: hidden;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hidden {
    display: none !important;
  }
  .affix {
    position: fixed;
  }
  

  .sub-image {
    width:50%;
  }

  .email-form {
    align-self: center;
    width: 50%;
}

.modal-close-btn {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 2.1em;
}

.small{
  font-size: .8em;
}

.email-icon{
  width:25%;
  margin: 10px auto;
}

@media (max-width: 420px) {
  .email-form{
    width:100%;
    padding:35px 0;
    z-index: 999;
  }

  .modal{
    top:12%;
  }

  .sub-image{
    width:100%;
    position: absolute;
    opacity: .6;
  }
  .sub-image image{
    object-fit: cover;
    height: 200px;
    width: 100%;
    object-position: 0% 0%;
  }

  .modal .flex{
    flex-direction: column;
  }
}

form{
  display: flex;
  flex-direction: column;
}

.modal form input{
  background-color: transparent;
  border: 1px #fff solid;
  padding:5px 20px;
  margin:5px 20px;
}

.modal form button{
  background-color: #fff;
  color: #364434;
  padding:5px 20px;
  margin:12px 20px;
  border-radius: 20px;
}

.hide-tag{
  position: absolute; 
  left: -5000px;
}