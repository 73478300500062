iframe{
    height: 80vh;
    width:100%;
    margin:auto;
}


@media only screen 
  and (max-device-width : 814px) { 
    iframe{
      height: 100vh;
      width: 100%;
  }
}
