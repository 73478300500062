.content-divider {
  width: 90px;
  background: theme('textColor.olive');
  height: 2px;
  margin-bottom: 16px;

}
.gallery{
  overscroll-behavior: contain;
  max-height: calc(100vh);
}


.gallery-close-btn{
  width:32px;
  height:32px;
  border: 1px #fff solid;
  border-radius: 50%;
  position: absolute;
  margin-left: 20px;
  font-size: 12px;
}
