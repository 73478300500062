.subscribe-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.subscribe-container .main-box {
  /* width: 85%; */
  height: 100%;
  border: 1px solid #0000001c;
  padding-bottom: 25px
}

.subscribe-container .main-box .header {
  font-size: 40px;
  color: white;
  background: black;
  padding-bottom: 13px;
  padding-top: 13px;
  letter-spacing: 3.5px;
}

.subscribe-container .price-tier-container {
  padding: 40px;
}

.subscribe-container .price-tier {
  margin-right: 20px;
  margin-top: 20px;
  border: 1px solid #0000001c;
}

.subscribe-container .price-tier-container .description {
  margin-top: 40px;
}

.subscribe-container .footer {
  margin-top: 45px;
}

.subscribe-container .category-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 17px;
}

.subscribe-container .icon-container {
  display: inline-block;
  width: 30px;
  margin-left: 9px;
}

.subscribe-container .counter {
  font-size: 35px;
}
