@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'suisse-int';
  src: url('/fonts/suisse/suisse-int-regular.otf');
}

@font-face {
  font-family: 'suisse-works';
  src: url('/fonts/suisse/suisse-works-regular.otf');
}

@font-face {
  font-family: 'suisse-cond';
  src: url('/fonts/suisse/suisse-cond-semi.otf');
}


@font-face {
  font-family: "Soehne-Leicht";
  src: url("/fonts/soehne/soehne-web-leicht.woff") format('woff');
}

@font-face {
  font-family: "Soehne-XLeicht";
  src: url("/fonts/soehne/soehne-web-extraleicht.woff") format('woff');
}

@font-face {
  font-family: "Soehne-Kraftig";
  src: url("/fonts/soehne/soehne-web-kraftig.woff") format('woff');
}

html,
body {
  padding: 0;
  margin: 0;
  color: rgb(24,24,24);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

img,
button {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 0, 0); 
}

button {
  font-family: mulish-semi;
  letter-spacing: .5px;
  height: 48px;
}

@media not all and (hover: none) {
  button:hover {
    opacity: .8;
  }
}

/* REMOVE ARROWS ON NUMBER INPUT */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.blog-body a{
  color: green;
  font-family: 'Soehne-Kraftig';
}
