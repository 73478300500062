/* CUSTOM STYLES GO HERE */

.issue-swiper-mb .swiper {
  width: 100%;
  height: calc(100vh - 50px);
}

/* .issue-swiper-mb .swiper-pagination {

} */

.issue-swiper-mb .swiper-pagination-fraction {
  font-size: 2rem;
}

.issue-swiper-mb .swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.issue-swiper-mb .swiper-slide img {
  display: block;
  width: 100% !important;
  height: calc(100vh - 50px);
}

.issue-swiper-mb .swiper {
  margin-left: auto;
  margin-right: auto;
}

.issue-swiper-mb .swiper-wrapper {
  align-items: center;
}
