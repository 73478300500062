.nav-menu {
  height: calc(100vh - 50px);
}

.logo{
  top:10px !important;
}

@media only screen and (max-width: 600px) {
  .logo{
    top:-5px !important;
  }
  .h-header{
    height:50px;
  }
} 